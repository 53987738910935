// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/health.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/healtyfamily.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/prevention.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/treatment.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/activity.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/healthyeating.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/appearance.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../../static/mc/page/v2/mediclub-block-2/mediclub-block-2/emotion.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-mediclub-block-2[data-v-3270eafd]{margin:0 var(--spacing-xl-1) var(--spacing-xl-1) var(--spacing-xl-1)}@media(min-width:768px){.cms-mediclub-block-2[data-v-3270eafd]{margin:0 0 var(--spacing-md-2) 0}}.cms-mediclub-block-2__container[data-v-3270eafd]{overflow-x:auto}.cms-mediclub-block-2__img-container[data-v-3270eafd]{display:flex;flex-wrap:wrap;gap:var(--spacing-sm-2);width:1160px}.cms-mediclub-block-2__item[data-v-3270eafd]{align-items:center;background-repeat:no-repeat;display:flex;flex-wrap:nowrap;height:97px;justify-content:center;line-height:1;padding-bottom:2.5rem;text-align:center;width:141px}.cms-mediclub-block-2__item-text[data-v-3270eafd]{color:var(--color-white);font-size:var(--font-size-sm-3);font-weight:var(--font-weight-black)}.cms-mediclub-block-2__item-text-additional[data-v-3270eafd]{font-size:var(--font-size-sm-2);font-weight:var(--font-weight-normal)}.cms-mediclub-block-2__item--background-1[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.cms-mediclub-block-2__item--background-2[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.cms-mediclub-block-2__item--background-3[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.cms-mediclub-block-2__item--background-4[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.cms-mediclub-block-2__item--background-5[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.cms-mediclub-block-2__item--background-6[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.cms-mediclub-block-2__item--background-7[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.cms-mediclub-block-2__item--background-8[data-v-3270eafd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
