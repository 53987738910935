import { render, staticRenderFns } from "./CmsMediclubBlock7.vue?vue&type=template&id=16b2db3c&scoped=true&"
import script from "./CmsMediclubBlock7.vue?vue&type=script&lang=js&"
export * from "./CmsMediclubBlock7.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclubBlock7.vue?vue&type=style&index=0&id=16b2db3c&prod&lang=scss&"
import style1 from "./CmsMediclubBlock7.vue?vue&type=style&index=1&id=16b2db3c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b2db3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
