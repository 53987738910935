import { render, staticRenderFns } from "./mediclub.vue?vue&type=template&id=26a3bf5a&scoped=true&"
import script from "./mediclub.vue?vue&type=script&lang=js&"
export * from "./mediclub.vue?vue&type=script&lang=js&"
import style0 from "./mediclub.vue?vue&type=style&index=0&id=26a3bf5a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a3bf5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsMediclubBlock1: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock1.vue').default,CmsMediclubBlock2: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock2.vue').default,CmsMediclubBlock3: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock3.vue').default,CmsMediclubBlock5: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock5.vue').default,CmsMediclubBlock6: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock6.vue').default,CmsMediclubBlock7: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock7.vue').default,CmsMediclubBlock8: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v2/CmsMediclubBlock8.vue').default,BaseStaticBlock: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseStaticBlock.vue').default})
