
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'
import storeCode from '~/mixins/storeCode'

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  mixins: [
    storeCode,
  ],

  middleware: ['hasRedirect'],
  async asyncData ({ store }) {
    await store.dispatch('staticBlock/getByIdentifiers', [
      'mediclub-block-1-v2',
      'mediclub-block-2-v2',
      'mediclub-block-3-v2',
      'mediclub-block-4-v2',
      'mediclub-block-5-v2',
      'mediclub-block-6-v2',
      'mediclub-block-7-v2',
      'mediclub-block-8-v2',
    ])

    store.dispatch('breadcrumbs/setBreadcrumbs', { name: 'MediClub' })
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
    }),
  },

  mounted () {
    this.$gtm.push({ event: 'page_view' })
  },
}
