import { render, staticRenderFns } from "./CmsMediclubBlock5.vue?vue&type=template&id=a7c99a84&scoped=true&"
import script from "./CmsMediclubBlock5.vue?vue&type=script&lang=js&"
export * from "./CmsMediclubBlock5.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclubBlock5.vue?vue&type=style&index=0&id=a7c99a84&prod&lang=scss&"
import style1 from "./CmsMediclubBlock5.vue?vue&type=style&index=1&id=a7c99a84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7c99a84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseHeading: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
