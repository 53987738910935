
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclubBlock7',
  mixins: [cmsBlock],
  data: () => ({
    play: false,
    video: [1, 2],
  }),

  computed: {
    video1 () {
      return `${this.blockContent[`video${this.video[0]}`]}${this.play ? '?autoplay=1' : ''}`
    },

    video2 () {
      return this.blockContent[`video${this.video[1]}`]
    },
  },

  methods: {
    videoSwitch () {
      this.play = true
      this.video.reverse()
    },
  },
}
