// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../static/mc/page/v2/mediclub-block-5/background.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-mediclub-block-5[data-v-a7c99a84]{background-color:var(--color-white);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;padding-top:70px;position:relative;top:-50px;z-index:var(--z-index-absolute)}.cms-mediclub-block-5__body[data-v-a7c99a84]{display:flex;flex-wrap:wrap;margin:0 auto;max-width:1160px;width:100%}.cms-mediclub-block-5__img-bg[data-v-a7c99a84],.cms-mediclub-block-5__text[data-v-a7c99a84]{height:auto}@media(min-width:768px){.cms-mediclub-block-5__img-bg[data-v-a7c99a84],.cms-mediclub-block-5__text[data-v-a7c99a84]{height:430px}}.cms-mediclub-block-5__img-bg[data-v-a7c99a84]{order:2;width:100%}@media(min-width:768px){.cms-mediclub-block-5__img-bg[data-v-a7c99a84]{order:1;width:50%}}.cms-mediclub-block-5__img[data-v-a7c99a84]{margin:0 auto;padding:var(--spacing-md-2) var(--spacing-md-2) 0}@media(min-width:768px){.cms-mediclub-block-5__img[data-v-a7c99a84]{left:0;position:absolute;top:-40px}}.cms-mediclub-block-5__text[data-v-a7c99a84]{display:flex;flex-direction:column;gap:var(--spacing-md-3);margin:0 auto;order:1;padding:var(--spacing-md-3);width:70%}@media(min-width:768px){.cms-mediclub-block-5__text[data-v-a7c99a84]{order:2;width:50%}}.cms-mediclub-block-5__text-first-part[data-v-a7c99a84]{color:#29b1e7;font-size:2rem}.cms-mediclub-block-5__text-second-part[data-v-a7c99a84]{color:var(--color-white);font-size:4rem}.cms-mediclub-block-5__list-container[data-v-a7c99a84]{color:var(--color-white);font-size:var(--font-size-sm-2);width:100%}.cms-mediclub-block-5__list[data-v-a7c99a84]{list-style:none}.cms-mediclub-block-5__list li[data-v-a7c99a84]{align-items:baseline;display:flex;gap:var(--spacing-sm-3);margin-bottom:var(--spacing-sm-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
