// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../static/mc/page/v2/mediclub-block-1/mediclub-block-1.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-mediclub-block-1[data-v-cf281a82]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:center;background-repeat:no-repeat;background-size:190%}@media(min-width:768px){.cms-mediclub-block-1[data-v-cf281a82]{background-position-y:-30px;background-size:120%;height:440px}}.cms-mediclub-block-1__text[data-v-cf281a82]{display:flex;flex-direction:column;gap:var(--spacing-md-3);padding:var(--spacing-md-3)}.cms-mediclub-block-1__header-md[data-v-cf281a82]{color:var(--color-primary-4);font-size:var(--font-size-md-2);font-weight:var(--font-weight-black)}.cms-mediclub-block-1__heading-first-line[data-v-cf281a82]{color:var(--color-primary-4)}.cms-mediclub-block-1__heading-second-line[data-v-cf281a82]{color:#29b1e7}.cms-mediclub-block-1__headers[data-v-cf281a82]{width:50%}.cms-mediclub-block-1__list-container[data-v-cf281a82]{color:#383838;font-size:var(--font-size-sm-3);width:100%}@media(min-width:575px){.cms-mediclub-block-1__list-container[data-v-cf281a82]{width:60%}}@media(min-width:1024px){.cms-mediclub-block-1__list-container[data-v-cf281a82]{width:55%}}.cms-mediclub-block-1__show-sm-down[data-v-cf281a82]{display:none}@media(max-width:1023.5px){.cms-mediclub-block-1__show-sm-down[data-v-cf281a82]{display:inline-block}}.cms-mediclub-block-1__show-md-up[data-v-cf281a82]{display:none}@media(min-width:1024px){.cms-mediclub-block-1__show-md-up[data-v-cf281a82]{display:inline-block}}.cms-mediclub-block-1__list[data-v-cf281a82]{list-style:none}.cms-mediclub-block-1__list li[data-v-cf281a82]{align-items:baseline;display:flex;gap:var(--spacing-sm-3);margin-bottom:var(--spacing-sm-3)}.cms-mediclub-block-1__buttons[data-v-cf281a82]{display:flex;gap:var(--spacing-lg-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
