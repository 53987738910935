
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclubBlock8',
  mixins: [cmsBlock],

  data: () => ({
    showAll: false,
  }),

  computed: {
    items () {
      return this.showAll ? this.blockContent.items : this.blockContent.items.slice(0, 4)
    },
  },
}
